import AutoComplete from 'places-autocomplete-react';

const BillerFormComponentB = (props) => {
  const billerobj = JSON.parse(props.jsondata);
  var r = 0;
  const saveobjectdata = (e) => {
    e.preventDefault();
    alert("Save Button clicked ");
  }

  const searchOptions = {
    country: ['ca', 'us'],
    type: ['address']
  }

  return (
    <div>
      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <button className="nav-link active" id="nav-addr-tab" data-bs-toggle="tab" data-bs-target="#nav-addr" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Address</button>
          <button className="nav-link" id="nav-bank-tab" data-bs-toggle="tab" data-bs-target="#nav-bank" type="button" role="tab" aria-controls="nav-bank" aria-selected="false">Bank Info</button>
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div className="tab-pane fade show active" id="nav-addr" role="tabpanel" aria-labelledby="nav-addr-tab">
          <div className="card">
            <div className="card-body">
              <AutoComplete
                placesKey="AIzaSyAVIf8BpyX3bUXFeRu3au-gMQF4cFZfRT4"
                inputId="address"
                searchOptions={searchOptions}
                setAddress={(addressObject) => console.log(addressObject)}
                required={true} />
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="nav-bank" role="tabpanel" aria-labelledby="nav-bank-tab">
          <div className="card">
            <div className="card-body">
              <form className="row g-3" id="profile_form_bank">
                <div className="col-md-6">
                  <label htmlFor="input-Bank-Name" className="form-label">Bank Name</label>
                  <input type="text" className="form-control" id="input-Bank-Name" value="Royal Bank of Canada" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="input-Account-Number" className="form-label">Account Number</label>
                  <input type="text" className="form-control" id="input-Account-Number" value="102394785" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="input-Routing-Number" class="form-label">Routing Number</label>
                  <input type="text" className="form-control" id="input-Routing-Number" value="10546" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="input-Account-Type" class="form-label">Account Type</label>
                  <select className="form-select" aria-label="Account Type" id="input-Account-Type" value="C" >
                    <option value="C">Checking</option>
                    <option value="S">Savings</option>
                    <option value="O">Other</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <label className="form-check-label" htmlFor="validated_chkbx"> Validated </label>
                  <input className="form-check mt-2" type="checkbox" id="validated_chkbx" value="" />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-12">
          <button type="submit" onClick={saveobjectdata} className="btn btn-primary">Save</button>
        </div>
      </div>
      </div>
      );
}
      export default BillerFormComponentB;