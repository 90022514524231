
const BulkLoadTemplate = (props) => {
        return (
                <div className="scroll-container d-flex">
                        <div className="card mr-3">
                                <h5 className="card-header">Customers</h5>
                                <div className="card-body">
                                        <div class="card-text text-wrap">
                                                <div id="drop-area">
                                                        <form className="my-form">
                                                                <p>Select using file dialog or by dragging and dropping the file into the region</p>
                                                                <input type="file" id="fileElem" multiple onchange="handleFiles(this.files)" />
                                                        </form>
                                                </div>
                                        </div>
                                </div>
                                <div className="card-footer">
                                        <div className="col-12 d-flex justify-content-end">
                                                <a href="/" class="btn btn-primary">Upload</a>
                                        </div>
                                </div>
                        </div>
                        <div className="card mr-3">
                        <h5 className="card-header">Service Locations</h5>
                                <div className="card-body">
                                        <div class="card-text text-wrap">
                                                <div id="drop-area">
                                                        <form className="my-form">
                                                                <p>Select using file dialog or by dragging and dropping the file into the region</p>
                                                                <input type="file" id="fileElem" multiple onchange="handleFiles(this.files)" />
                                                        </form>
                                                </div>
                                        </div>
                                </div>
                                <div className="card-footer">
                                        <div className="col-12 d-flex justify-content-end">
                                                <a href="/" class="btn btn-primary">Upload</a>
                                        </div>
                                </div>
                        </div>
                        <div className="card mr-3">
                        <h5 className="card-header">Programs</h5>
                                <div className="card-body">
                                        <div class="card-text text-wrap">
                                                <div id="drop-area">
                                                        <form className="my-form">
                                                                <p>Select using file dialog or by dragging and dropping the file into the region</p>
                                                                <input type="file" id="fileElem" multiple onchange="handleFiles(this.files)" />
                                                        </form>
                                                </div>
                                        </div>
                                </div>
                                <div className="card-footer">
                                        <div className="col-12 d-flex justify-content-end">
                                                <a href="/" class="btn btn-primary">Upload</a>
                                        </div>
                                </div>
                        </div>
                        <div className="card mr-3">
                        <h5 className="card-header">Accounts</h5>
                                <div className="card-body">
                                        <div class="card-text text-wrap">
                                                <div id="drop-area">
                                                        <form className="my-form">
                                                                <p>Select using file dialog or by dragging and dropping the file into the region</p>
                                                                <input type="file" id="fileElem" multiple onchange="handleFiles(this.files)" />
                                                        </form>
                                                </div>
                                        </div>
                                </div>
                                <div className="card-footer">
                                        <div className="col-12 d-flex justify-content-end">
                                                <a href="/" class="btn btn-primary">Upload</a>
                                        </div>
                                </div>
                        </div>
                        <div className="card mr-3">
                        <h5 className="card-header">Charges</h5>
                                <div className="card-body">
                                        <div class="card-text text-wrap">
                                                <div id="drop-area">
                                                        <form className="my-form">
                                                                <p>Select using file dialog or by dragging and dropping the file into the region</p>
                                                                <input type="file" id="fileElem" multiple onchange="handleFiles(this.files)" />
                                                        </form>
                                                </div>
                                        </div>
                                </div>
                                <div className="card-footer">
                                        <div className="col-12 d-flex justify-content-end">
                                                <a href="/" class="btn btn-primary">Upload</a>
                                        </div>
                                </div>
                        </div>

                        <div className="card mr-3">
                        <h5 className="card-header">Payments</h5>
                                <div className="card-body">
                                        <div class="card-text text-wrap">
                                                <div id="drop-area">
                                                        <form className="my-form">
                                                                <p>Select using file dialog or by dragging and dropping the file into the region</p>
                                                                <input type="file" id="fileElem" multiple onchange="handleFiles(this.files)" />
                                                        </form>
                                                </div>
                                        </div>
                                </div>
                                <div className="card-footer">
                                        <div className="col-12 d-flex justify-content-end">
                                                <a href="/" class="btn btn-primary">Upload</a>
                                        </div>
                                </div>
                        </div>

                </div>

        );
}
export default BulkLoadTemplate;

