import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Topbar from '../components/Topbar';
import EmailTemplate from '../docserv/EmailTemplate';

class EmailTemplates extends Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  componentDidMount() {
  }

  render() {
    return <>
      <Header />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar />
          <div className="container-fluid">
            <h1 className="h3 mb-2 text-success">Email Templates</h1>
            <p className="mb-4"></p>
            <EmailTemplate />
          </div>
          <div className="container"></div>;
        </div>
      </div>
      <Footer />
    </>
  }
}
export default EmailTemplates;