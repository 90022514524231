import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Topbar from '../components/Topbar';

class OtherUtilitites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
    };
  }

  componentDidMount() {
  }

  render() {
    return <>
      <Header />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar />
          <div className="container h-100 d-flex flex-column">
            <div className="row h-25 bg-transparent"></div>
            <div className="row justify-content-center">
              <div className="spinner-grow text-primary"></div>
              <div className="spinner-grow text-success"></div>
              <div className="spinner-grow text-info"></div>
              <div className="spinner-grow text-warning"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  }

}
export default OtherUtilitites;