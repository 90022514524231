import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import undrawprofile from "../img/undraw_profile.svg";
import profile1 from '../img/undraw_profile_1.svg';
import profile2 from '../img/undraw_profile_2.svg';
import profile3 from "../img/undraw_profile_3.svg";

var billerdata = null;
function Topbar(props) {
    console.log("Session Item ["+window.sessionStorage.getItem("biller")+']');
    if (window.sessionStorage.getItem("biller") == null) {
        console.log("Biller Data did not persist");
    } else {
        billerdata = window.sessionStorage.getItem("biller");
        console.log("Found Biller Data" + JSON.parse(billerdata).biller_id);
    }
   // console.log("Biller Data is "+billerdata+ '['+(typeof billerdata)+']'+ (billerdata === undefined)+'['+(billerdata == undefined)+']'+(billerdata == null)
   // +']'+ (billerdata === "undefined")+'['+ (billerdata == "undefined")+']');
    if (billerdata === "undefined") {
        console.log("Before Data Fetch");
        const params = {
            objecttype: "dashboard",
            numrecords: "25"
        };
        fetch(process.env.REACT_APP_API_URI,
            {
                method: 'POST',
                body: JSON.stringify(params),
                cache: 'no-cache',
                mode: 'cors',
                headers: {
                    'Cache-Control': 'no-cache',
                    'Accept': '*/*',
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
            .then(response => {
                console.log('Response is: ' + response);
                return response.json()
            })
            .then(response => {

                console.log("Response 2 is "+response);
                window.sessionStorage.setItem("biller", response);
            })
            .finally(() => console.log("finally in Topbar"));

    } else {
        console.log('In Else Part '+window.sessionStorage.getItem("biller"));
        billerdata = JSON.parse(window.sessionStorage.getItem("biller"));
    }

    return (
        <>
            {/*<!-- Topbar -->*/}
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                {/*<!-- Sidebar Toggle (Topbar) -->*/}
                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"></i>
                </button>

                {/*<!-- Topbar Search -->*/}
                {/*
                <!--form
                    className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                    <div className="input-group">
                        <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..."
                            aria-label="Search" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button className="btn btn-primary" type="button">
                                    <i className="fas fa-search fa-sm"></i>
                                </button>
                            </div>
                    </div>
                </form-->
    */}
                <a className="navbar-brand text-success" href="/profile">{billerdata.biller_id}-{billerdata.biller_name} ({billerdata.email})</a>

                {/*<!-- Topbar Navbar -->*/}
                <ul className="navbar-nav ml-auto">

                    {/*<!-- Nav Item - Search Dropdown (Visible Only XS) -->*/}
                    <li className="nav-item dropdown no-arrow d-sm-none">
                        <a className="nav-link dropdown-toggle" href="/" id="searchDropdown" role="button"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-search fa-fw"></i>
                        </a>
                        {/*<!-- Dropdown - Messages -->*/}
                        <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                            aria-labelledby="searchDropdown">
                            <form className="form-inline mr-auto w-100 navbar-search">
                                <div className="input-group">
                                    <input type="text" className="form-control bg-light border-0 small"
                                        placeholder="Search for..." aria-label="Search"
                                        aria-describedby="basic-addon2" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button">
                                            <i className="fas fa-search fa-sm"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li>

                    {/*<!-- Nav Item - Alerts -->*/}
                    <li className="nav-item dropdown no-arrow mr-5">
                        <a className="nav-link dropdown-toggle" href="/" id="alertsDropdown" role="button"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <FontAwesomeIcon icon={icon({name: "bell"})} />
                            {/*<!-- Counter - Alerts -->*/}
                            <span className="badge badge-danger badge-counter">3+</span>
                        </a>
                        {/*<!-- Dropdown - Alerts -->*/}
                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="alertsDropdown">
                            <h6 className="dropdown-header">
                                Alerts Center
                            </h6>
                            <a className="dropdown-item d-flex align-items-center" href="/">
                                <div className="mr-3">
                                    <div className="icon-circle bg-primary">
                                        <i className="fas fa-file-alt text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 12, 2019</div>
                                    <span className="font-weight-bold">A new monthly report is ready to download!</span>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="/">
                                <div className="mr-3">
                                    <div className="icon-circle bg-success">
                                        <i className="fas fa-donate text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 7, 2019</div>
                                    $290.29 has been deposited into your account!
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="/">
                                <div className="mr-3">
                                    <div className="icon-circle bg-warning">
                                        <i className="fas fa-exclamation-triangle text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 2, 2019</div>
                                    Spending Alert: We've noticed unusually high spending for your account.
                                </div>
                            </a>
                            <a className="dropdown-item text-center small text-gray-500" href="/">Show All Alerts</a>
                        </div>
                    </li>

                    {/*<!-- Nav Item - Messages -->*/}
                    <li className="nav-item dropdown no-arrow mr-5">
                        <a className="nav-link dropdown-toggle" href="/" id="messagesDropdown" role="button"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <FontAwesomeIcon icon={icon({name: "envelope"})} />
                            {/*<!-- Counter - Messages -->*/}
                            <span className="badge badge-danger badge-counter">7</span>
                        </a>
                        {/*<!-- Dropdown - Messages -->*/}
                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="messagesDropdown">
                            <h6 className="dropdown-header">
                                Message Center
                            </h6>
                            <a className="dropdown-item d-flex align-items-center" href="/">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src={profile1}
                                        alt="..." />
                                    <div className="status-indicator bg-success"></div>
                                </div>
                                <div className="font-weight-bold">
                                    <div className="text-truncate">Hi there! I am wondering if you can help me with a
                                        problem I've been having.</div>
                                    <div className="small text-gray-500">Emily Fowler · 58m</div>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="/">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src={profile2}
                                        alt="..." />
                                    <div className="status-indicator"></div>
                                </div>
                                <div>
                                    <div className="text-truncate">I have the photos that you ordered last month, how
                                        would you like them sent to you?</div>
                                    <div className="small text-gray-500">Jae Chun · 1d</div>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="/">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src={profile3}
                                        alt="..." />
                                    <div className="status-indicator bg-warning"></div>
                                </div>
                                <div>
                                    <div className="text-truncate">Last month's report looks great, I am very happy with
                                        the progress so far, keep up the good work!</div>
                                    <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="/">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                                        alt="..." />
                                    <div className="status-indicator bg-success"></div>
                                </div>
                                <div>
                                    <div className="text-truncate">Am I a good boy? The reason I ask is because someone
                                        told me that people say this to all dogs, even if they aren't good...</div>
                                    <div className="small text-gray-500">Chicken the Dog · 2w</div>
                                </div>
                            </a>
                            <a className="dropdown-item text-center small text-gray-500" href="/">Read More Messages</a>
                        </div>
                    </li>

                    <div className="topbar-divider d-none d-sm-block"></div>

                    {/*<!-- Nav Item - User Information -->*/}
                    <li className="nav-item dropdown no-arrow mx-5">
                        <a className="nav-link dropdown-toggle" href="/" id="userDropdown" role="button"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{billerdata.contact_name}</span>
                            <img className="img-profile rounded-circle"
                                src={undrawprofile} alt="none" />
                        </a>
                        {/*<!-- Dropdown - User Information -->*/}
                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="userDropdown">
                            <a className="dropdown-item" href="/profile">
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                Profile
                            </a>
                            <a className="dropdown-item" href="/">
                                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                Settings
                            </a>
                            <a className="dropdown-item" href="/">
                                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                Activity Log
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="/" data-bs-toggle="modal" data-target="#logoutModal">
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Logout
                            </a>
                        </div>
                    </li>

                </ul>

            </nav>
            {/*<!-- End of Topbar -->*/}
        </>
    )
}

export default Topbar;