import React, { Component } from 'react';

class ForgotPassword extends Component { 
  state = { 
  }
  
  render () {                                   
    return (
      <div>
          <h1> This is Forgot Password Component</h1>
</div>

    );
 }
}

export default ForgotPassword;