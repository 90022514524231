import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Dashboard from './Dashboard';

function Home()  {   
        return (
         <>
          <Header />
          <Dashboard />
          <Footer />
         </>
        );
     }

  export default Home;