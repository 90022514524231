import React, { Component } from 'react';
import submitbtn from '../img/btn_submit.jpg';
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";

class SignIn extends Component { 
  state = { 
  }
  
  render () {                                   
      return (
          <div id="background" className="loginPage">
          <div id="wrapper">
              <div id="bar">
                  <div className="content">
                       <div className="logo typeface-js">Customer Service Web Interface</div>
                      <form method="post" name="frmLogin" id="frmLogin" action="index.html">
                      <div className="login">
                          <div className="inputContainer"><input type="text" name="txtUserName" id="txtUserName" value="User Name" className="inputNormal" /></div>
                          <div className="inputContainer"><input type="password" name="txtPassword" id="txtPassword" value="Password" className="inputNormal password" /></div>
                          <div className="clearfix"></div>
                      </div>
                      <div className="submitContainer"><input type="image" src={submitbtn} alt="Submit" className="btnLogin" /></div>
                      </form>
                      <div className="clearfix"></div>
                  </div>
              </div>
              <div className="content forget_password">
                  <Link to="/forgotid">Forget User ID</Link> | 
                  <Link to="/forgotpassword">Forgot Password</Link>
                  <Link to="/signup"><strong>New User Signup</strong></Link>
                </div>
              <div className="content timeStamp">
                   <span className="time">8:05pm</span>
                   <span className="date">Wed, Aug 03, 2011</span>
              </div>
          </div>
      </div>
      )
   }
}

export default SignIn;