import React, { Component } from 'react';
import continuebtn from '../img/btn_continue.png';
// importing Link from react-router-dom to navigate to 
// different end points.
import { Link } from "react-router-dom";

class SignUp extends Component { 
  state = { 
  }
  
  render () {                                   
      return (
          <div id="background" className="forgotUserID">
          <div id="wrapper">
              <div id="bar">
                  <div className="content">
                      <Link to="/" className="logo typeface-js">Customer Service Web Interface</Link>
                      <h2>NEW REGISTRATION</h2>
                      <div className="clearfix"></div>
                  </div>
              </div>
              <div className="content bodyTop"></div>
              <div className="content body bodyRegisterZipCode">
                   <div className="padding">
                          <div className="step registerStepOne"></div>
                      <p className="subHeading"><strong>Before you begin, please have a copy of your Utility bill on hand.</strong></p>
                      <div id="formRegister">
                           <form name="frmRegister" id="frmRegister" method="post" action="register-step-two.html">
                          <div className="inputContainer">
                              <label for="txtCISAccountNumber1">CIS Account Number</label>
                              <input type="text" name="txtCISAccountNumber1" id="txtCISAccountNumber1" maxlength="1" value="" className="inputSmall CISAccountNumber" /><input type="text" name="txtCISAccountNumber2" id="txtCISAccountNumber2" maxlength="1" value="" className="inputSmall CISAccountNumber" /><input type="text" name="txtCISAccountNumber3" id="txtCISAccountNumber3" maxlength="1" value="" className="inputSmall CISAccountNumber" /><input type="text" name="txtCISAccountNumber4" id="txtCISAccountNumber4" maxlength="1" value="" className="inputSmall CISAccountNumber" /><input type="text" name="txtCISAccountNumber5" id="txtCISAccountNumber5" maxlength="1" value="" className="inputSmall CISAccountNumber" /><input type="text" name="txtCISAccountNumber6" id="txtCISAccountNumber6" maxlength="1" value="" className="inputSmall CISAccountNumber" /><input type="text" name="txtCISAccountNumber7" id="txtCISAccountNumber7" maxlength="1" value="" className="inputSmall CISAccountNumber" /><input type="text" name="txtCISAccountNumber8" id="txtCISAccountNumber8" maxlength="1" value="" className="inputSmall CISAccountNumber" /><input type="text" name="txtCISAccountNumber9" id="txtCISAccountNumber9" maxlength="1" value="" className="inputSmall CISAccountNumber" /><input type="text" name="txtCISAccountNumber10" id="txtCISAccountNumber10" maxlength="1" value="" className="inputSmall CISAccountNumber" /><input type="text" name="txtCISAccountNumber11" id="txtCISAccountNumber11" maxlength="1" value="" className="inputSmall CISAccountNumber" /><input type="text" name="txtCISAccountNumber12" id="txtCISAccountNumber12" maxlength="1" value="" className="inputSmall CISAccountNumber" /><input type="text" name="txtCISAccountNumber13" id="txtCISAccountNumber13" maxlength="1" value="" className="inputSmall CISAccountNumber" /><input type="text" name="txtCISAccountNumber14" id="txtCISAccountNumber14" maxlength="1" value="" className="inputSmall CISAccountNumber" />
                               </div>
                          <div className="clearfix"></div>
                          <div className="inputContainer">
                              <label for="txtZipCode">Postal Code / Zip Code</label>
                              <input type="text" name="txtZipCode" id="txtZipCode" value="" className="inputMain zipCode" />
                          </div>
                          <div className="clearfix"></div>
                          <div className="inputContainer">
                              <label for="txtPhoneNumber1">Phone Number</label>
                              <input type="text" name="txtPhoneNumber1" id="txtPhoneNumber1" maxlength="1" value="" className="inputSmall phoneNumber" /><input type="text" name="txtPhoneNumber2" id="txtPhoneNumber2" maxlength="1" value="" className="inputSmall phoneNumber" /><input type="text" name="txtPhoneNumber3" id="txtPhoneNumber3" maxlength="1" value="" className="inputSmall phoneNumber" /><span className="spacer"> - </span><input type="text" name="txtPhoneNumber4" id="txtPhoneNumber4" maxlength="1" value="" className="inputSmall phoneNumber" /><input type="text" name="txtPhoneNumber5" id="txtPhoneNumber5" maxlength="1" value="" className="inputSmall phoneNumber" /><input type="text" name="txtPhoneNumber6" id="txtPhoneNumber6" maxlength="1" value="" className="inputSmall phoneNumber" /><span className="spacer"> - </span><input type="text" name="txtPhoneNumber7" id="txtPhoneNumber7" maxlength="1" value="" className="inputSmall phoneNumber" /><input type="text" name="txtPhoneNumber8" id="txtPhoneNumber8" maxlength="1" value="" className="inputSmall phoneNumber" /><input type="text" name="txtPhoneNumber9" id="txtPhoneNumber9" maxlength="1" value="" className="inputSmall phoneNumber" /><input type="text" name="txtPhoneNumber10" id="txtPhoneNumber10" maxlength="1" value="" className="inputSmall phoneNumber" />
                               </div>
                          <div className="clearfix"></div>
                          <div className="submitContainer">
                              <input type="image" src={continuebtn} alt="Continue" />
                          </div>
                               </form>
                      </div>
                   </div>
                </div>
              <div className="content bodyBottom"></div>
              <div className="content footer">
                  <span className="parentCompany"><Link to="/">Parent Company Name</Link></span>
                  <span className="copyrights">Copyright &copy; 2000 - 2011, <Link to="/">TrekSoftware</Link> | <Link to="/">Privacy Policy</Link> | <Link to="/">Legal Notice</Link></span>
              </div>
        </div>
      
      </div>
      );
   }
}

export default SignUp;