import React from 'react';
import $ from 'jquery';
//import CustomScript from './CustomScript';

//import jquery from 'jquery';
class Footer extends React.Component {
    
     constructor (props) {
        super(props);
        this.handlescroll = this.handlescroll.bind(this);
     }
    componentDidMount () {
        window.jquery = $;  
        const script = document.createElement("script");
        
        script.src = "../js/sb-admin-2.js"; 
        script.async = true;
        script.defer = true; 
        script.type="javascript";
        document.body.appendChild(script);
        const bootscript = document.createElement("script");
        bootscript.src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js";
        bootscript.async = true;
        bootscript.type="text/javascript";
        document.body.appendChild(bootscript);

        //const addscript = document.createElement("script");
        
        //<CustomScript scriptToAppend="../js/addressmap.js" />;
        
        /*
        addscript.src = "../js/addressmap.js"; 
        addscript.type="javascript";
        addscript.async=false;
        addscript.defer=false;
        document.body.appendChild(addscript);
        
        const mapscript = document.createElement("script");
        mapscript.src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAVIf8BpyX3bUXFeRu3au-gMQF4cFZfRT4&amp;libraries=places&amp;callback=initMap&amp;solution_channel=GMP_QB_addressselection_v1_cA"
        mapscript.async=false;
        mapscript.defer=false;
        mapscript.type="text/javascript";
        document.body.appendChild(mapscript);
        */
                
        /*
        
            const button1 = jquery("#sidebarToggle");
            console.log(button1); */
          //  console.log("added script");
          //  alert('Button Elment is '+$('#sidebarToggle'));
          //  console.log($('#sidebarToggle'));
        
    }
    
        
   // Smooth scrolling using jQuery easing
   handlescroll () {
    var anchor = $(this);
    $('html, body').stop().animate({
      scrollTop: ($(anchor.attr('href')).offset().top)
    }, 1000, 'easeInOutExpo');
    this.preventDefault();
    }
    
    render() {
        return (
            <>
            <a className="scroll-to-top rounded" onClick={this.handlescroll} href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
           <footer className="d-flex flex-wrap sticky-footer">
            <div className="container my-auto">
               <div className="copyright text-center my-auto">
                 Copyright - TrekSoftware LLC - 2023
               </div>
               </div>
           </footer>
          </>
        );
      }
}
export default Footer;