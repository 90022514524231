import BillTemplate1 from '../img/BillTemplate1.png'
import BillReadyBiller from '../img/BillReadyBiller.png'
import BillReadyCustomer from '../img/BillReadyCustomer.png'
import PaymentDueBiller from '../img/PaymentDueBiller.png'
import PaymentDueCustomer from '../img/PaymentDueCustomer.png'
import ProgramSuspendedBiller from '../img/ProgramSuspendedBiller.png'
import ProgramSuspendedCustomer from '../img/ProgramSuspendedCustomer.png'

const SMSTemplate = (props) => {
        return (
                <div className="scroll-container d-flex">
                        <div className="card mr-3">
                                <h5 className="card-header">Bill Ready</h5>
                                <div className="card-body">
                                        <div class="card-text text-wrap">
                                                Your Joe's Heating and HVAC bill for acct ending in 6361 is ready.
                                                The amount is $100.00, and is due May 30, 2023.
                                                Please check your email for payment options.
                                        </div>
                                </div>
                                <div className="card-footer">
                                        <div className="col-12 d-flex justify-content-end">
                                                <a href="#" class="btn btn-primary">Edit</a>
                                        </div>
                                </div>
                        </div>
                        <div className="card mr-3">
                                <h5 className="card-header">Payment Reminder</h5>
                                <div className="card-body">
                                        <div class="card-text text-wrap">
                                                Your Joe's Heating and HVAC bill for acct ending in 6361 is due on May 30, 2023.
                                                The amount due is $100.00.
                                                Please check your email for payment options.
                                                Note: This is friendly reminder. Ignore this message if a payment has already been made.
                                        </div>
                                </div>
                                <div className="card-footer">
                                        <div className="col-12 d-flex justify-content-end">
                                                <a href="#" class="btn btn-primary">Edit</a>
                                        </div>
                                </div>
                        </div>
                        <div className="card mr-3">
                                <h5 className="card-header">Past Due Amount</h5>
                                <div className="card-body">
                                        <div class="card-text text-wrap">
                                                Your Joe's Heating and HVAC bill has not yet been paid.
                                                A late payment fee or a penalty amount of $3.00 will be applied to your next bill.
                                                Please check your email for payment options.
                                        </div>
                                </div>
                                <div className="card-footer">
                                        <div className="col-12 d-flex justify-content-end">
                                                <a href="#" class="btn btn-primary">Edit</a>
                                        </div>
                                </div>
                        </div>
                        <div className="card mr-3">
                                <h5 className="card-header">Program Suspension</h5>
                                <div className="card-body">
                                        <div class="card-text text-wrap">
                                                Your Joe's Heating and HVAC bill for acct ending in 6361 is has been Suspended due to Non-Payment.
                                                Please call us at 666-222-4444 at your earliest convenience to avoid penalties and service interruptions.
                                                Please check your email for payment options.
                                        </div>
                                </div>
                                <div className="card-footer">
                                        <div className="col-12 d-flex justify-content-end">
                                                <a href="#" class="btn btn-primary">Edit</a>
                                        </div>
                                </div>
                        </div>
                        <div className="card mr-3">
                                <h5 className="card-header">Program Completion</h5>
                                <div className="card-body">
                                        <div class="card-text text-wrap">Your Joe's Heating and HVAC bill for acct ending in 6361 has been completed.
                                                The amount of the Final Bill is $100.00, and is due May 30, 2023.
                                                Please check your email for payment options. Thank you for being a loyal customer.
                                        </div>
                                </div>
                                <div className="card-footer">
                                        <div className="col-12 d-flex justify-content-end">
                                                <a href="#" class="btn btn-primary">Edit</a>
                                        </div>
                                </div>
                        </div>

                </div>

        );
}
export default SMSTemplate;

