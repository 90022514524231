import BillTemplate1 from '../img/BillTemplate1.png'
import BillReadyBiller from '../img/BillReadyBiller.png'
import BillReadyCustomer from '../img/BillReadyCustomer.png'
import PaymentDueBiller from '../img/PaymentDueBiller.png'
import PaymentDueCustomer from '../img/PaymentDueCustomer.png'
import ProgramSuspendedBiller from '../img/ProgramSuspendedBiller.png'
import ProgramSuspendedCustomer from '../img/ProgramSuspendedCustomer.png'

const EmailTemplate = (props) => {
    return (
        <div className="scroll-container text-primary d-flex">
            <div className="imgdiv text-center">
                    <h4 className="text">Bill Image</h4>
                    <img width="360px" src={BillTemplate1} alt="Billing" />
            </div>
            <div className="imgdiv text-center">
                    <h4>Bill Ready Email - Biller </h4>
                    <img  width="360px" src={BillReadyBiller} alt="Bill Ready Email - Biller" />
            </div>
            <div className="imgdiv text-center">
                    <h4>Bill Ready Email - Customer</h4>
                    <img  width="360px" src={BillReadyCustomer} alt="Bill Ready Email - Customer" />
            </div>
            <div className="imgdiv text-center">
                    <h4>Past Due Email - Customer</h4>
                    <img width="360px" src={PaymentDueCustomer} alt="Past Due Email - Customer" />
            </div>
            <div className="imgdiv text-center">
                    <h4>Past Due Email - Biller</h4>
                    <img width="360px" src={PaymentDueBiller} alt="Past Due Email - Biller" />
            </div>
            <div className="imgdiv text-center">
                    <h4>Program Suspened Notice - Biller</h4>
                    <img  width="360px" src={ProgramSuspendedBiller} alt="Program Suspended - Biller" />
            </div>
            <div className="imgdiv text-center">
                    <h4>Program Suspended Notice - Customer</h4>
                    <img  width="360px" src={ProgramSuspendedCustomer} alt="Program Suspended - Customer" />
            </div>
        </div>

    );
}
export default EmailTemplate;

