import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from 'jquery';
import React from 'react';
import { Link } from 'react-router-dom';
//import icons from "@fortawesome/free-solid-svg-icons";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import translogo from '../img/final.png';
import transicon from '../img/trekiconnb.png';

//import home from '../views/Home';
function Navigation() {
    window.jquery = $;
    function handleClik() {
        //alert('Button Clicked');
        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");
        /*
        if ($(".sidebar").hasClass("toggled")) {
              //$('.sidebar .collapse').collapse('hide');
              alert('Hiding');
        };
        */
    }
    function handleSubMenu() {
      //  alert('Sub Menu Clicked');
    }
       
	return (
	  <>
        {/* Sidebar */}
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

            {/* Sidebar - Brand */}
            <a className="sidebar-brand d-flex text-success align-items-center justify-content-center" href="/">
                <div className="sidebar-brand-icon rotate-n-15">
                {/* <FontAwesomeIcon icon={icon({name: 'face-laugh-wink'})} /> */}
                <img src={transicon} width="40"></img>
                </div>
                <div className="sidebar-brand-text mx-1 " style={{color: '#38EF7D'}}>Flex Biller Portal</div>
            </a>
            {/* Divider */}
            <hr className="sidebar-divider my-0" />

            {/*<!-- Nav Item - Dashboard -->*/}
            <li className="nav-item active">
            <a className="nav-link" href="/">
            <i><FontAwesomeIcon icon={icon({name: "tachograph-digital"})} /></i>
                    <span>Dashboard</span></a>
            </li>

            {/*<!-- Divider -->*/}
            <hr className="sidebar-divider" />

            {/*<!-- Heading -->*/}
            <div className="sidebar-heading">
                Interface
            </div>

            {/*<!-- Nav Item - Pages Collapse Menu -->*/}
            <li className="nav-item">
                <a className="nav-link collapsed" href="#collapseTwo"  data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseTwo">
                    <i><FontAwesomeIcon icon={icon ({name:"gear"})} /></i>
                    <span>Setup</span>
                </a>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Configuration</h6>
                        <Link className="collapse-item" to="/profile"><i><FontAwesomeIcon icon={icon ({name: "id-card"})} /></i> Biller Profile</Link>
                        <Link className="collapse-item" to="/categories"><i><FontAwesomeIcon icon={icon ({name: "list-check"})} /></i> Categories</Link>
                        <Link className="collapse-item" to="/programs"><FontAwesomeIcon icon={icon({name: "bars-progress"})} /> Biller Programs</Link>
                        <Link className="collapse-item" to="/customers"><FontAwesomeIcon icon={icon({name: "users-line"})} /> Customers</Link>
                        <Link className="collapse-item" to="/servlocations"><FontAwesomeIcon icon={icon({name: "address-book"})} /> Service Locations</Link>
                    </div>
                </div>
            </li>

            <li className="nav-item">
                <a className="nav-link collapsed" href="#collapseThree"  data-bs-toggle="collapse" data-bs-target="#collapseThree"
                    aria-expanded="true" aria-controls="collapseThree">
                    <i><FontAwesomeIcon icon={icon ({name:"list-check"})} /></i>
                    <span>Billing</span>
                    
                </a>
                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Program</h6>
                        <Link className="collapse-item" to="/accounts"><i><FontAwesomeIcon icon={icon ({name: "file-invoice-dollar"})} /></i> Accounts</Link>
                        <Link className="collapse-item" to="/updates"><FontAwesomeIcon icon={icon({name: "file-pen"})} /> Changes</Link>
                        <Link className="collapse-item" to="/adjustments"><FontAwesomeIcon icon={icon({name: "file-invoice"})} /> Adjustments</Link>
                        <Link className="collapse-item" to="/payments"><FontAwesomeIcon icon={icon({name: "credit-card"})} /> Payments</Link>
                    </div>
                </div>
            </li>
            {/*<!-- Nav Item - Reports Collapse Menu -->*/}
            <li className="nav-item">
                <a className="nav-link collapsed" href="#collapseReports"  data-bs-toggle="collapse" data-bs-target="#collapseReports"
                    aria-expanded="true" aria-controls="collapseReports">
                    <i><FontAwesomeIcon icon={icon({name: "screwdriver-wrench"})} /></i>
                    <span>Reports</span>
                </a>
                <div id="collapseReports" className="collapse" aria-labelledby="headingReports"
                    data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">List</h6>
                        <Link className="collapse-item" to="/charges">Monthly Charges</Link>
                        <Link className="collapse-item" to="/unpaid">Unpaid Items</Link>
                        <Link className="collapse-item" to="/pendingprog">Pending Requests</Link>
                    </div>
                </div>
            </li>

            {/*<!-- Nav Item - Utilities Collapse Menu -->*/}
            <li className="nav-item">
                <a className="nav-link collapsed" href="#collapseUtilities"  data-bs-toggle="collapse" data-bs-target="#collapseUtilities"
                    aria-expanded="true" aria-controls="collapseUtilities">
                    <i><FontAwesomeIcon icon={icon({name: "screwdriver-wrench"})} /></i>
                    <span>Utilities</span>
                </a>
                <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities"
                    data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Check</h6>
                        <Link className="collapse-item" to="/etemplates">Templates</Link>
                        <Link className="collapse-item" to="/communications">Communications</Link>
                        <Link className="collapse-item" to="/bulkload">Bulk Loads</Link>
                    </div>
                </div>
            </li>

            {/*<!-- Divider -->*/}
            <hr className="sidebar-divider" />

            {/*<!-- Heading -->*/}
            <div className="sidebar-heading">
                Addons
            </div>

            {/*<!-- Nav Item - Pages Collapse Menu -->*/}
            <li className="nav-item">
                <a className="nav-link collapsed" href="/" onClick={handleSubMenu} data-bs-toggle="collapse" data-bs-target="#collapsePages"
                    aria-expanded="true" aria-controls="collapsePages">
                    <i className="fas fa-fw fa-folder"></i>
                    <span>Configurable Pages</span>
                </a>
                <div id="collapsePages" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Login Screens:</h6>
                        <Link className="collapse-item" to="/outilities">Login</Link>
                        <Link className="collapse-item" to="/">Register</Link>
                        <Link className="collapse-item" to="/">Forgot Password</Link>
                        <div className="collapse-divider"></div>
                        <h6 className="collapse-header">Other Pages:</h6>
                        <Link className="collapse-item" to="/">404 Page</Link>
                        <Link className="collapse-item" to="/">Blank Page</Link>
                    </div>
                </div>
            </li>

            {/*<!-- Nav Item - Charts -->*/}
            <li className="nav-item">
                <a className="nav-link" href="/">
                    <i className="fas fa-fw fa-chart-area"></i>
                    <span>Additional Charts</span></a>
            </li>

            {/*<!-- Nav Item - Tables -->*/}
            <li className="nav-item">
                <a className="nav-link" href="/">
                    <i className="fas fa-fw fa-table"></i>
                    <span>Custom Tables</span></a>
            </li>

            {/*<!-- Divider -->*/}
            <hr className="sidebar-divider d-none d-md-block" />

            {/*<!-- Sidebar Toggler (Sidebar) -->*/}
            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" onClick={handleClik} id="sidebarToggle"></button>
            </div>

            {/*<!-- Sidebar Message -->*/}
            <div className="sidebar-card d-none d-lg-flex">
                <img src={translogo} width="90%" alt="" />
            </div>

        </ul>
        {/*<!-- End of Sidebar -->*/}
		</>
	);
  }

export default Navigation;