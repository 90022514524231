import React, { Component } from 'react';

class ForgotMyId extends Component { 
  state = { 
  }
    render () {                                   
    return (
      <div>
          <h1> This is Forgot Id Component</h1>
</div>

    );
 }
}

export default ForgotMyId;