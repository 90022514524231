const BillerFormComponentA = (props) => {
  const billerobj = JSON.parse(props.jsondata);
  const billerdata = billerobj.Profile;
  
  console.log("In comp A"+JSON.stringify(billerobj));
  var r = 0;
  const saveobjectdata = (e) => {
    const currentform = e.target.form;
    e.preventDefault();
    console.log(e.target.form);
    alert("Save Button clicked "+currentform.id);
  }

  const clearobjectdata = (e) => {
    const currentform = e.target.form;
    e.preventDefault();
    console.log(e.target.form);
    currentform.reset();
    alert("Discarded all changes");
  }
  

  return (
    <form className="row g-3" id='profile_form_A'>
    {
      Object.keys(billerdata).map((datakey, j) => {
        console.log("In A- Data Key is ["+datakey+"] value is "+datakey.endsWith("_meta"));
        if (!datakey.trim().endsWith("_meta")) {
          if (datakey.endsWith("_chkbx")) {
            var newkey = (datakey + '').substring(0, (datakey + '').length - 6);
            return (
              <div  key={r++} className="col-md-6">
                <label  key={r++} className="form-check-label" htmlFor={datakey}> { newkey.toUpperCase() } </label>
                <input  key={r++} className="form-check mt-2" type="checkbox" value="" id={datakey} />
              </div>
            );
          } else if ((datakey.endsWith("_list")) || (datakey === "Status")) {
            return (
              <div key={r++} className="col-md-6">
              <label key={r++} htmlFor={"input-" + datakey} className="form-label">{datakey}</label>
              <select key={r++} className="form-select" aria-label="Status" defaultValue={billerdata[datakey]} disabled>
                  <option value='A'>Active</option>
                  <option value="S">Suspended</option>
                  <option value="I">Inactive</option>
              </select>
              </div>
              )
          } else if (datakey.endsWith("_rb")) {    
              return (
              <div key={r++} className="col-md-6">
                <label key={r++} htmlFor={"input-" + datakey} className="form-label">{datakey}</label>
                <input key={r++} type="text" className="form-control" id={"input-" + datakey} />
              </div>
            )
          } else {
            return (
              <div key={r++} className="col-md-6">
                <label key={r++} htmlFor={"input-" + datakey} className="form-label">{datakey}</label>
                <input key={r++} type="text" className="form-control" id={"input-" + datakey} defaultValue={billerdata[datakey]} />
              </div>
            )
          }
        }  else {
          return '';
        }
      } 
         )
         
      }
<div className="col-12 d-flex justify-content-end">
  <button type="submit" onClick={saveobjectdata} className="btn btn-primary me-2">Save</button>
  <button type="reset" onClick={clearobjectdata} className="btn btn-warning">Reset</button>
</div>
    </form >
    );
}

export default BillerFormComponentA;