import React, { useRef, useState } from 'react';

const DatePicker = (props) => {
    const [date, setDate] = useState('');
    const dateInputRef = useRef(null);
    const dateid = props.id;

    const handleChange = (e) => {
        setDate(e.target.value);
    };

    return (
        <div>
            <input
                type="date"
                id={dateid}
                defaultValue={props.defvalue}
                onChange={handleChange}
                ref={dateInputRef}
            />
        </div>
    );
};

export default DatePicker;