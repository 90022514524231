import { useEffect, useRef } from "react";
//import DataTable from "datatables.net";
import $ from 'jquery';
// Import necessary styles
import "datatables.net-dt/css/jquery.dataTables.min.css";

const DataTableComponent = (props) => {

  $.DataTable = require('datatables.net')
  //console.log("Data Received: "+props.jsondata);
  const myjsonobj = JSON.parse(props.jsondata);
  const dataobject = myjsonobj["dataobject"];
  // let myjson = JSON.parse(JSON.stringify(myjsonarr));
  //let newcolumns = JSON.parse(JSON.stringify(tabcolumns));
  //console.log('My Data '+myjson);
  const tableRef = useRef(myjsonobj);

  const tableName = "mydatatable1";

  useEffect(() => {
    // When component loads, fill table with data
    /*
    new DataTables(tableRef.current, {
      data: dataSet,
      columns: [
        { title: "Name" },
        { title: "Occupation" },
        { title: "City" },
        { title: "ZIP" },
        { title: "Birthday" },
        { title: "Salary" },
      ],
    });
    */
    if (myjsonobj.hasOwnProperty("_notfound")) {
      const table = $(`#${tableName}`).DataTable(
        );
   }
    const myjsonarr = myjsonobj["data"];
    const mycolumnslist = myjsonobj["columnslist"];
    
    let tabcolumns = [];
    let mytabledata = [];
    let newkey = null;

    if (myjsonarr.length > 0) {
      for (const datakey of Object.keys(mycolumnslist)) {
        if (!datakey.includes('_meta')) {             

            if (datakey.endsWith("_chkbx")) {
              newkey = (datakey + '').substring(0, (datakey + '').length - 6);
            } else if (datakey.endsWith("_list")) {
              newkey = (datakey + '').substring(0, (datakey + '').length - 5);
            } else if (datakey.endsWith("_rb")) {    
              newkey = (datakey + '').substring(0, (datakey + '').length - 3);
            } else if (datakey.endsWith("_disp")) {
              newkey = (datakey + '').substring(0, (datakey + '').length - 5); 
            } else {
              newkey = datakey;
            }
            tabcolumns.push({'title': newkey}); 
        }
    }
        
/*
      var firstrow = myjsonarr[0];
      console.log(firstrow);
      tabcolumns =
        Object.keys(firstrow).filter((colkey) => !colkey.includes("_meta")).map((colkey, j) => {
          console.log("key " + colkey + " Object " + firstrow[colkey]);
          let newobj = {};
          newobj.title = colkey;
          console.log(newobj);
          return newobj
       
        });
        */
      console.log(tabcolumns);
      mytabledata = myjsonarr.map((rowkey) => {
        return (
          Object.keys(rowkey).filter((colkey) => !colkey.includes("_meta")).map((colkey, j) => {
                return rowkey[colkey];
          })
        )
      })
    //  console.log(mytabledata);
    }
    
    /*
    new DataTables(tableRef.current, {
      data: mytabledata,
      columns: tabcolumns,
    })
    */
  // console.log(mytabledata);
    const table = $(`#${tableName}`).DataTable(
      {
        data: mytabledata,
        columns: tabcolumns,
        scrollCollapse: true,
        scrollX: true,
        sScrollXInner: "100%",
        retrieve: true,
        paging: false,
        scrollY: '300px'

      });
      //table.data = mytabledata;
      $(`#${tableName} tbody`).on('click', 'tr', function () {
        var data = table.row(this).data();
       // alert('You clicked on ' + data[0] + "'s row");
        var dataform = $(`#${dataobject+'_form'}`);
        console.log(dataform);


        $.each( dataform, function( key, value ) {
          console.log( key + ": " + value );
          var newdataform = value;
          const inputs = newdataform.elements;
          // Iterate over the form controls
          for (let i = 0; i < inputs.length; i++) {
              if (inputs[i].nodeName === "INPUT" && inputs[i].type === "text") {
                  // Update text input
                  //const inputByIndex = inputs[0];
                  //const inputByName = inputs["username"];
                   //inputs[i].value.toLocaleUpperCase();
                   inputs[i].value = data[i];
              } else if (inputs[i].nodeName === "SELECT") {
                //inputs[i].value.toLocaleUpperCase();
                inputs[i].value = data[i];
              }
          }
          
        });
         
        
        
    });
      console.log(table);
  }, [myjsonobj, dataobject]);

   
  if (myjsonobj.hasOwnProperty('_notfound')) {
    return <div><h1>No Data Found</h1></div>
  } else {
  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">Displaying Rows for {dataobject}</h6>
      </div>
      <div className="card-body">
        <div className="table-responsive">
            <table id={tableName} className="table table-striped table-hover responsive nowrap" ref={tableRef}></table>
        </div>
      </div>
    </div>
       );
  }
      /*
    return (
        <table><tbody>
    { 
        
        myjsonarr.map((rowkey) => {      
           // const jsonrow = myjsonarr[rowkey];
           console.log(rowkey);
           
          return (
            <tr key={r++}>
          {
           
            Object.keys(rowkey).map((colkey, j) => {
              return <td key={r++}>{rowkey[colkey]}</td>
            })  
             
            }
            </tr>)
            
         })
        
      }
      </tbody></table>
      );
      */
    /*
    return (
        <table>
            <thead>
            {Object.keys(myjson).map((innerAttr, index) => { 
                return (
                    <th key={index}> {innerAttr} </th>
                )
                })}
            </thead>
        <tr>
        {Object.keys(myjson).map((innerAttr, index) => {
            return (
                <td>{myjson[innerAttr]}</td>
            )})
        }   
        </tr>  
        </table> 
          
    ); */
    
}

export default DataTableComponent;
