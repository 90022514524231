import React, { Component } from 'react';
import DataFormComponent from '../components/DataFormComponent';
import DataTableComponent from '../components/DataTableComponent';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Topbar from '../components/Topbar';

class Balancing extends Component {
    constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          data: null,
          rules: []
        };
      }
    
      componentDidMount() {
        fetch(process.env.REACT_APP_API_URI)
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({
                isLoaded: true,
                rules: result.rules
              });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                isLoaded: true,
                error,
                data: '{ "dataobject": "Balancing", "data": [{"attr1":"abcdef", "attr2":"12345", "attr3":"hello"},{"attr1":"at1", "attr2":"at2", "attr3":"at3"}]}'  
              });
            }
          )
      }
    
      render() {
        const { error, isLoaded, rules, data } = this.state;
        if (!isLoaded) {
          return <>
                <Header />
                <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                <Topbar />
                <div className="container-fluid"> Value of Data is {data}
                <h1 className="h3 mb-2 text-gray-800">Balancing</h1>
                <p className="mb-4">This is AR Balancing page</p>
                <DataFormComponent jsondata={data} />
                <DataTableComponent jsondata={data} />
                </div>
                <div className="container">Error: {error.message}</div>;
                </div>
                </div>
                <Footer />
                </>
        } else {
          return (
            <div className="container h-100 d-flex flex-column">
            <div className="row h-25 bg-transparent"></div>
            <div className="row justify-content-center">
              <div className="spinner-grow text-primary"></div>
              <div className="spinner-grow text-success"></div>
              <div className="spinner-grow text-info"></div>
              <div className="spinner-grow text-warning"></div>
            </div>
          </div>);
        } 
      }
    }

    export default Balancing;