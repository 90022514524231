import $ from 'jquery';

const DataFormComponent = (props) => {
  const myjsonobj = JSON.parse(props.jsondata);
  const dataobject = myjsonobj.dataobject;
  const myjsonarr = myjsonobj.data;
  const mycolumnslist = myjsonobj.columnslist;
  let tabcolumns = [];
  var r = 0;
  var firstrow = {};
  var newkey;
  if (myjsonarr.length > 0) {
    firstrow = myjsonarr[0];
    console.log(firstrow);
      Object.keys(firstrow).map((colkey, j) => {
        if (!colkey.includes("_meta")) {
          console.log("key " + colkey + " Object " + firstrow[colkey]);
          let newobj = {};
          newobj.title = colkey;
          console.log(newobj);
          return newobj
        }
      });
    console.log(tabcolumns);
  }

  const saveobjectdata = (e) => {
    const currentform = e.target.form;
    const savedataobj = {};
    e.preventDefault();
    //console.log(e.target.form);
    console.log('Save Button Elements: '+currentform.elements);
   // alert("Save Button clicked " + currentform.id);
   const inputs = currentform.elements;
      // Iterate over the form controls
      for (let i = 0; i < inputs.length; i++) {
          if ((inputs[i].nodeName === 'SELECT') || (inputs[i].nodeName === "INPUT" && inputs[i].type === "text")) {
              // Update text input
              //const inputByIndex = inputs[0];
              //const inputByName = inputs["username"];
               //inputs[i].value.toLocaleUpperCase();
               savedataobj[i]= inputs[i].type+'~|'+inputs[i].name+'~|'+inputs[i].id+'~|'+inputs[i].value;
               console.log('Inputs '+i+' is key '+inputs[i].name+' Value '+inputs[i].value);
          } else {
            console.log(inputs[i].nodeName);
          }
      }
      console.log("Saving Object Values "+JSON.stringify(savedataobj));
  }

  const newobjectdata = (e) => {
    const currentform = e.target.form;
    e.preventDefault();
    console.log(e.target.form);
    //alert("New Button clicked " + currentform.id);
    const inputs = currentform.elements;
      // Iterate over the form controls
      for (let i = 0; i < inputs.length; i++) {
          if (inputs[i].nodeName === "INPUT" && inputs[i].type === "text") {
              // Update text input
              //const inputByIndex = inputs[0];
              //const inputByName = inputs["username"];
               //inputs[i].value.toLocaleUpperCase();
               inputs[i].value = '';
          }
      }
      
    }
    
  
  const clearobjectdata = (e) => {
    const currentform = e.target.form;
    e.preventDefault();
    console.log(e.target.form);
    currentform.reset();
    //alert("Discarding all changes");
  }

  return (

    <form className="row g-3" id={dataobject + '_form'}>
      {
        Object.keys(firstrow).map((datakey, j) => {
          console.log("In A- Data Key is [" + datakey + "] value is " + datakey.endsWith("_meta"));
          if (!datakey.trim().endsWith("_meta")) {
            if (datakey.endsWith("_chkbx")) {
              newkey = (datakey + '').substring(0, (datakey + '').length - 6);
              return (
                <div key={r++} className="col-md-6">
                  <label key={r++} className="form-check-label" htmlFor={datakey}> {newkey} </label>
                  <input key={r++} name={datakey} className="form-check mt-2" type="checkbox" value="" id={datakey} />
                </div>
              );
            } else if (datakey === "Status") {
              return (
                <div key={r++} className="col-md-6">
                  <label key={r++} htmlFor={"input-" + datakey} className="form-label">{datakey}</label>
                  <select key={r++} id={"input-" + datakey} name={"input-" + datakey} className="form-select" aria-label="Status" defaultValue={firstrow[datakey]} >
                    <option value='A'>Active</option>
                    <option value='P'>Pending</option>
                    <option value='C'>Completed</option>
                    <option value="S">Suspended</option>
                    <option value='X'>Cancelled</option>
                    <option value="I">Inactive</option>
                  </select>
                </div>
              )
            } else if (datakey.endsWith("_list")) {
              newkey = (datakey + '').substring(0, (datakey + '').length - 5);
              if (newkey === "Association") {
                return (
                  <div key={r++} className="col-md-6">
                    <label key={r++} htmlFor={"input-" + datakey} className="form-label">{newkey}</label>
                    <select key={r++} id={"input-" + datakey} name={"input-" + datakey} className="form-select" aria-label="Status" defaultValue={firstrow[datakey]} >
                      <option value='C'>Customer</option>
                      <option value='P'>Service Location</option>
                      <option value='A'>Account</option>
                      <option value='O'>One-Time</option>
                    </select>
                  </div>
                )
              } else {
                return (
                  <div key={r++} className="col-md-6">
                    <label key={r++} htmlFor={"input-" + datakey} className="form-label">{newkey}</label>
                    <select key={r++} className="form-select" aria-label="Status" defaultValue={firstrow[datakey]} >
                      <option value='P'>Pending</option>
                      <option value='A'>Active</option>
                      <option value='C'>Completed</option>
                      <option value="S">Suspended</option>
                      <option value='X'>Cancelled</option>
                      <option value="I">Inactive</option>
                    </select>
                  </div>
                )
              }
            } else if (datakey.endsWith("_rb")) {
              newkey = (datakey + '').substring(0, (datakey + '').length - 3);
              return (
                <div key={r++} className="col-md-6">
                  <label key={r++} htmlFor={"input-" + datakey} className="form-label">{newkey}</label>
                  <input key={r++} type="text" className="form-control" id={"input-" + datakey} />
                </div>
              )
            } else if (datakey.endsWith("_disp")) {
              newkey = (datakey + '').substring(0, (datakey + '').length - 5);
              return (
                <div key={r++} className="col-md-6">
                  <label key={r++} htmlFor={"input-" + datakey} className="form-label">{newkey}</label>
                  <input key={r++} type="text" className="form-control" id={"input-" + datakey} defaultValue={firstrow[datakey]} readOnly />
                </div>
              )
            } else {
              return (
                <div key={r++} className="col-md-6">
                  <label key={r++} htmlFor={"input-" + datakey} className="form-label">{datakey}</label>
                  <input key={r++} type="text" className="form-control" id={"input-" + datakey} defaultValue={firstrow[datakey]} />
                </div>
              )
            }
          } else {
            return '';
          }
        }
        )

      }
      <div className="col-12 d-flex justify-content-end">
        <button type="button" onClick={newobjectdata} className="btn btn-primary me-2">New</button>
        <button type="submit" onClick={saveobjectdata} className="btn btn-success me-2">Save</button>
        <button type="reset" onClick={clearobjectdata} className="btn btn-warning">Reset</button>
      </div>
    </form >
  );
}

export default DataFormComponent;