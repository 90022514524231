// service to generate PDF Report 
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import blacklogo from "../img/black.png";

// define a generatePDF function that accepts a tickets argument
const generatePDF = (props) => {
  // initialize jsPDF
  const doc = new jsPDF({
     orientation: 'l',
     unit: 'mm',
     format: 'letter',
     putOnlyUsedFonts:true
    }   
    );
  doc.addImage(blacklogo, "PNG", 10, 20, 180, 200 );
  doc.text("PDF Report Template Sample", 10,45);
  doc.addPage("string", "l");

  console.log(props);

  const myjsonobj = JSON.parse(props);
  if (myjsonobj.hasOwnProperty("_notfound")) {
  
  }
  const dataobject = myjsonobj.dataobject;
  const myjsonarr = myjsonobj.data;

  // define the columns we want and their titles
  const mycolumnslist = myjsonobj.columnslist;
  // define an empty array of rows

    let tabcolumns = [];
    let mytabledata = [];
    let newkey = null;
    let cols = 1;

    if (myjsonarr.length > 0) {
      for (const datakey of Object.keys(mycolumnslist)) {
        if (!datakey.includes('_meta')) {             
              newkey = datakey;
              cols++;
            if (cols > 9) {
                break;
            }
            tabcolumns.push(newkey); 
        }
    }
    }

  // for each ticket pass all its data into an array
  mytabledata = myjsonarr.map((rowkey) => {
    cols = 1;
    return (
      Object.keys(rowkey).filter((colkey) => !colkey.includes("_meta")).map((colkey, j) => {
            cols++;
            if (cols > 9) return;
            return rowkey[colkey];
      })
    )
  })
  
  doc.setFontSize(10);
  autoTable(doc, { head: [tabcolumns],
                   body:  mytabledata}, { startY: 25 });
  doc.setFontSize(15);
  doc.setTextColor("green");
  /*doc.text("Total Amount: "+TotalAmount, 200, 120);
  doc.text("Due Date: "+DueDate, 200, 140);
  */
  if (myjsonobj.hasOwnProperty("summarydata")) {
      const billdate = myjsonobj.summarydata['Min Charge Date'];
      const billyear = myjsonobj.summarydata['YearValue'];
      const billmonth = myjsonobj.summarydata['MonthName'];
      const TotalAmount = myjsonobj.summarydata['Total Amount'];
      const DueDate  = myjsonobj.summarydata['Max Due Date'];
      let billsummary = [];
      billsummary.push("Total Amount Due: "+TotalAmount);
      billsummary.push("Due Date: "+DueDate);
    
      // startY is basically margin-top
      console.log(billyear+" "+billmonth);
      // Title. and margin-top + margin-left
      doc.text("Monthly ["+dataobject+"] - For the month of "+billmonth+" - "+billyear, 14, 10);
      doc.text(billsummary, 200, 190 );
  }
  const date = Date().split(" ");
  // we use a date string to generate our filename.
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
  
  
  //doc.text("Monthly ["+dataobject+"] - For the month of May 2023.", 14, 15);
  // we define the name of our PDF file.
  doc.save(`report_${dateStr}.pdf`);
};

export default generatePDF;