import React, { Component } from 'react';
import DataTableComponent from '../components/DataTableComponent';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Topbar from '../components/Topbar';
import generatePDF from '../docserv/generatePDF';

var billerdata = null;

class Charges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      data: null,
      datestring: "20220901"
    };
    this.selectdatevalue = this.selectdatevalue.bind(this);
    if (window.sessionStorage.getItem("biller") == null) {
      console.log("Biller Data did not persist");
    } else {
      billerdata = window.sessionStorage.getItem("biller");
      console.log("Found Biller Data" + JSON.parse(billerdata).biller_id);
    }
  }
  fetchData = (selecteddate) => {

    let biller_id = "1001";
    if (billerdata != null) {
      biller_id = JSON.parse(billerdata).biller_id;
      console.log("Biller ID is " + biller_id);
    }
    const params = {
      objecttype: "monthlycharge",
      numrecords: "25",
      biller: biller_id,
      datevalue: selecteddate
    };
    console.log("Parameters passed: " + JSON.stringify(params));
    fetch(process.env.REACT_APP_API_URI,
      {
        method: 'POST',
        body: JSON.stringify(params),
        cache: 'no-cache',
        mode: 'cors',
        headers: {
          'Cache-Control': 'no-cache',
          'Accept': '*/*',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      .then(response => {
        console.log('Response is: ' + response);
        return response.json()
      })
      .then(
        (response) => {
          console.log('Then ' + response);
          var errormessage = '';
          if (response.hasOwnProperty('_notfound')) {
            errormessage = "No Data Found";

          }
          this.setState({
            error: errormessage,
            isLoaded: true,
            data: JSON.stringify(response),
            datestring: selecteddate
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
          this.setState({
            isLoaded: true,
            error,
            data: '{ "dataobject": "ChargesListing", "_notfound": "Error while data fetch", "data": []}',
            datestring: selecteddate
          });
        }
      )
  }

  selectdatevalue(e) {
    //  e.preventDefault();
    //this.setState({datestring: e.target.value});
    //alert("Set the state to "+e.target.value);
    console.log("State Value " + e.target.value);
    //    this.setState({isLoaded: false, data:"{}", datevalue:e.target.value});
    this.setState({ isLoaded: false });
    this.fetchData(e.target.value);
  }

  componentDidMount() {
    this.fetchData(this.state.datestring);
  }


  render() {
    var { error, isLoaded, dataobject, data, datestring } = this.state;
    if (isLoaded) {
      return (
        <>
          <Header />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar />
              <div className="container-fluid">
                <div className="container">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-6">
                          <label htmlFor="chargemonth" className="form-label mr-2">Charge Month:  </label>
                          <select id="chargemonth" onChange={this.selectdatevalue} value={datestring}>
                            <option value="20240901">Sep-2024</option>
                            <option value="20230901">Sep-2023</option>
                            <option value="20230501">May-2023</option>
                            <option value="20220701">Jul-2022</option>
                          </select></div>
                        <div className="col-6 d-flex justify-content-end">
                          <button className="btn btn-primary mr-2" onClick={() => generatePDF(data)}>PDF</button>
                          <button type="reset" onClick={() => alert('Not Available')} className="btn btn-warning m">Excel</button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <DataTableComponent jsondata={data} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )
    } else {
      return (
        <div className="container h-100 d-flex flex-column">
            <div className="row h-25 bg-transparent"></div>
            <div className="row justify-content-center">
              <div className="spinner-grow text-primary"></div>
              <div className="spinner-grow text-success"></div>
              <div className="spinner-grow text-info"></div>
              <div className="spinner-grow text-warning"></div>
            </div>
          </div>);
    }
  }
}

export default Charges;