import React, { Component } from 'react';
import DataTableComponent from '../components/DataTableComponent';
import DatePicker from '../components/DatePicker';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Topbar from '../components/Topbar';
import generatePDF from '../docserv/generatePDF';

var billerdata = null;

class Charges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      data: null,
      startdatestring: "20200501",
      enddatestring: "20230501"
    };
    this.runUnpaidReport = this.runUnpaidReport.bind(this);
    if (window.sessionStorage.getItem("biller") == null) {
      console.log("Biller Data did not persist");
    } else {
      billerdata = window.sessionStorage.getItem("biller");
      console.log("Found Biller Data" + JSON.parse(billerdata).biller_id);
    }
  }
  fetchData = (selectedstartdate, selectedenddate) => {

    var biller_id = "1001";
    if (billerdata != null) {
      biller_id = JSON.parse(billerdata).biller_id;
      console.log("Biller ID is " + biller_id);
    }
    const params = {
      objecttype: "unpaidbalance",
      numrecords: "25",
      biller: biller_id,
      startdatevalue: selectedstartdate,
      enddatevalue: selectedenddate
    };
    console.log("Parameters passed: " + JSON.stringify(params));
    fetch(process.env.REACT_APP_API_URI,
      {
        method: 'POST',
        body: JSON.stringify(params),
        cache: 'no-cache',
        mode: 'cors',
        headers: {
          'Cache-Control': 'no-cache',
          'Accept': '*/*',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      .then(response => {
        console.log('Response is: ' + response);
        return response.json()
      })
      .then(
        (response) => {
          console.log('Then ' + response);
          var errormessage = '';
          if (response.hasOwnProperty('_notfound')) {
            errormessage = "No Data Found";

          }
          this.setState({
            error: errormessage,
            isLoaded: true,
            data: JSON.stringify(response),
            startdatestring: selectedstartdate,
            enddatestring: selectedenddate
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
          this.setState({
            isLoaded: true,
            error,
            data: '{ "dataobject": "UnpaidListing", "_notfound": "Error while data fetch", "data": []}',
            startdatestring: selectedstartdate,
            enddatestring: selectedenddate
          });
        }
      )
  }


  runUnpaidReport() {
    console.log("Start Date Value is " + document.getElementById("startdate").value)
    console.log("End Date Value is " + document.getElementById("enddate").value)
    this.setState({ isLoaded: false });
    this.fetchData(document.getElementById("startdate").value, document.getElementById("enddate").value);
  }
  componentDidMount() {
    this.fetchData(this.state.startdatestring, this.state.enddatestring);
  }


  render() {
    var { error, isLoaded, dataobject, data, startdatestring, enddatestring } = this.state;
    if (isLoaded) {
      return ( <>
        <Header />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
                <div className="container">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-6 d-flex">
                          <label htmlFor='startdate' className="form-label mx-2">Start Date: </label>
                          <DatePicker id="startdate" defvalue={startdatestring} />
                          <label htmlFor='enddate' className="form-label mx-2">End Date: </label>
                          <DatePicker id="enddate" defvalue={enddatestring} />
                        </div>  
                        <div className="col-6 d-flex justify-content-end">
                          <button className="btn btn-success mr-2" onClick={this.runUnpaidReport}>Report</button>
                          <button className="btn btn-primary mr-2" onClick={() => generatePDF(data)}>PDF</button>
                          <button className="btn btn-warning mr-2" onClick={() => alert('Not Available')} >Excel</button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <DataTableComponent jsondata={data} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <Footer />
      </>
         );
    } else {
      return (
        <div className="container h-100 d-flex flex-column">
            <div className="row h-25 bg-transparent"></div>
            <div className="row justify-content-center">
              <div className="spinner-grow text-primary"></div>
              <div className="spinner-grow text-success"></div>
              <div className="spinner-grow text-info"></div>
              <div className="spinner-grow text-warning"></div>
            </div>
          </div>);
    }
  }
}

export default Charges;