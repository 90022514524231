import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
  //import faker from 'faker';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Revenue Comparison by Program Type',
      },
    },
  };
  
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'];
  //const labels = ['WHTR', 'WSFT', 'FURN', 'AC', 'APUR', 'EQPT'];
  
  export const data = {
    labels,
    datasets: [
      {
        label: 'WHTR',
        data: [650, 690, 654, 559, 499, 480, 509, 529, 601],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'WSFT',
        data: [150, 160, 104, 159, 112, 109, 118, 135, 114],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: 'FURN',
        data: [350, 290, 254, 159, 102, 111, 25, 67, 55],
        backgroundColor: 'rgba(253, 12, 15, 0.5)',
      },
      {
        label: 'AC',
        data: [400, 400, 454, 559, 902, 811, 825, 567, 255],
        backgroundColor: 'rgba(15, 22, 245, 0.5)',
      },
    ],
  };

  export default function RBarChart() {
    return <Bar options={options} data={data} />;
  }