import React, { Component } from 'react';
import DataFormComponent from '../components/DataFormComponent';
import DataTableComponent from '../components/DataTableComponent';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Topbar from '../components/Topbar';

var billerdata = null;

class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      data: null
    };
    if (window.sessionStorage.getItem("biller") == null) {
      console.log("Biller Data did not persist");
    } else {
      billerdata = window.sessionStorage.getItem("biller");
      console.log("Found Biller Data" + JSON.parse(billerdata).biller_id);
    }
  }
  componentDidMount() {
    var biller_id = "1001";
    if (billerdata != null) {
      console.log(billerdata);
      biller_id = JSON.parse(billerdata).biller_id;
      console.log("Biller ID is " + biller_id);
    }
    console.log("From Account " + biller_id);
    const params = {
      objecttype: "account",
      numrecords: "25",
      biller: biller_id
    };
    fetch(process.env.REACT_APP_API_URI,
      {
        method: 'POST',
        body: JSON.stringify(params),
        cache: 'no-cache',
        mode: 'cors',
        headers: {
          'Cache-Control': 'no-cache',
          'Accept': '*/*',
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
      .then(response => {
        console.log('Response is: ' + response);
        return response.json()
      })
      .then(
        (response) => {
          console.log('Then ' + response);
          this.setState({
            isLoaded: true,
            data: JSON.stringify(response)
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
          this.setState({
            isLoaded: true,
            error,
            data: '{ "dataobject": "Accounts", "data": []}'
          });
        }
      )
  }


  render() {
    const { error, isLoaded, dataobject, data } = this.state;
    if (isLoaded) {
      return <>
        <Header />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="accordion" id={dataobject + "_page"}>
                <div className="container">{error == null ? '' : 'Error: ' + error.message}</div>
                <div className="accordion-item" id="custac1">
                  <h2 className="accordion-header" id="headingac1">
                    <button className="accordion-button collapsed" id="buttonac1" type="button"
                      data-bs-toggle="collapse" data-bs-target="#collapseac1"
                      aria-expanded="false" aria-controls="collapseac1">
                      <h3>Account Setup</h3>
                    </button>
                  </h2>
                  <div id="collapseac1" className="accordion-collapse collapse"
                    aria-labelledby="headingac1">
                    <div className="accordion-body" id="bodyac1">
                      <div className="panel panel-default" id="panelac1">
                        <DataFormComponent jsondata={data} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item" id="custac2">
                  <h2 className="accordion-header" id="headingac2">
                    <button className="accordion-button collapsed" id="buttonac2" type="button"
                      data-bs-toggle="collapse" data-bs-target="#collapseac2"
                      aria-expanded="true" aria-controls="collapseac2">
                      <h3>Accounts Listing</h3>
                    </button>
                  </h2>
                  <div id="collapseac2" className="accordion-collapse collapse show"
                    aria-labelledby="headingac2">
                    <div className="accordion-body" id="bodyac2">
                      <div className="panel panel-default" id="panelac2">
                        <DataTableComponent jsondata={data} />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    } else  {
      return (
        <div className="container h-100 d-flex flex-column">
        <div className="row h-25 bg-transparent"></div>
        <div className="row justify-content-center">
          <div className="spinner-grow text-primary"></div>
          <div className="spinner-grow text-success"></div>
          <div className="spinner-grow text-info"></div>
          <div className="spinner-grow text-warning"></div>
        </div>
      </div>);
    } 
    
  }
}

export default Accounts;