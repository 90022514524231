import React, { Component } from 'react';
import PageContents from '../components/PageContents';
import Topbar from '../components/Topbar';

class Dashboard extends Component {
      constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          biller: null
        };
      }
    
      componentDidMount() {
        /*let response = await fetch(("http://localhost:8080/customers",
           {
            credentials: 'include',
            method: 'GET',
            cache: 'no-cache',
            mode: 'cors',
            headers: {'Cache-Control': 'no-cache', 'Accept':'* /*', 'Content-Type':'application/json'},
           }); 
           */
        const billerdata = window.sessionStorage.getItem("biller");
        if (billerdata == null) {
        const params = {
            objecttype: "dashboard",
            numrecords: "25" 
        }; 
        fetch(process.env.REACT_APP_API_URI, 
        {
          method: 'POST',
          body: JSON.stringify(params),
          cache: 'no-cache',
          mode: 'cors',
          headers: {'Cache-Control': 'no-cache', 
                    'Accept':'*/*', 
                    'Content-type': 'application/json; charset=UTF-8',},
         })
         .then(response => {
          console.log('Response is: ',response);
          return response.json()
        })
        .then(
            (response) => {
              console.log('Then ',response);
              this.setState({
                isLoaded: true,
                data: JSON.stringify(response.Profile)
              });
              window.sessionStorage.setItem("biller", JSON.stringify(response.Profile));
              console.log("Biller is Set to ",JSON.parse(window.sessionStorage.getItem("biller")).biller_name);
              
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              console.log(error);
              this.setState({
                isLoaded: true,
                error,
                data: '{ "dataobject": "Customers", "data": [{"attr1":"abcdef", "attr2":"12345", "attr3":"hello"},{"attr1":"at1", "attr2":"at2", "attr3":"at3"}]}'  
              });
            }
          )
        } else {
          this.setState({
            isLoaded: true,
            data: JSON.stringify(window.sessionStorage.getItem("biller"))
          });
        }
      }
    
      render() {
        const { error, isLoaded, data } = this.state;
        if (error) {
          return<div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                <Topbar billerdata={data}/> 
                <PageContents />
                <div className="container">Error: {error.message}</div>;
                </div>
                </div>
        } else if (!isLoaded) {
          return (
            <div className="container h-100 d-flex flex-column">
            <div className="row h-25 bg-transparent"></div>
            <div className="row justify-content-center">
              <div className="spinner-grow text-primary"></div>
              <div className="spinner-grow text-success"></div>
              <div className="spinner-grow text-info"></div>
              <div className="spinner-grow text-warning"></div>
            </div>
          </div>);
        } else if (data) {
          return (
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                <Topbar billerdata={data}/> 
                <PageContents />
                <div className="container">Status: Loaded Successfully</div>;
                </div>
                </div>
          );
        } else if (!data) {
          return<div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                <Topbar billerdata={data}/> 
                <PageContents />
                <div className="container">Error: No Biller Data</div>;
                </div>
                </div>

        }
      }
    }

    export default Dashboard;