import React, { Component } from 'react';
import Home from './views/Home';
//import Dashboard from './views/Dashboard';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faCoffee, faHouseChimney } from '@fortawesome/free-solid-svg-icons';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './css/sb-admin-2.min.css';
import Accounts from './views/Accounts';
import Adjustments from './views/Adjustments';
import Balancing from './views/Balancing';
import Profile from './views/BillerProfile';
import BulkLoad from './views/BulkLoad';
import Categories from './views/Categories';
import Charges from './views/Charges';
import Communications from './views/Communications';
import Customers from './views/Customers';
import EmailTemplates from './views/EmailTemplates';
import ForgotId from './views/ForgotMyId';
import ForgotPassword from './views/ForgotPassword';
import OtherUtilities from './views/OtherUtilities';
import Payments from './views/Payments';
import PendingProg from './views/PendingProg';
import Programs from './views/Programs';
import ServLocations from './views/ServLocations';
import SignIn from './views/SignIn';
import SignUp from './views/SignUp';
import Unpaid from './views/UnpaidItems';
import Updates from './views/Updates';


library.add(faCheckSquare, faCoffee, faHouseChimney)


class App extends Component {
  
  render() {
    
    return (
      <>
       <Router>
        <Routes>
              <Route exact path='/' element = { <Home />} />
              <Route path='/' element = { <Home />}  /> 
              <Route path='/profile' element = { <Profile />}  /> 
              <Route path='/categories' element = { <Categories />}  /> 
              <Route path='/programs' element = { <Programs />}  /> 
              <Route path='/customers' element = { <Customers />}  /> 
              <Route path='/servlocations' element = { <ServLocations />}  /> 
              <Route path='/accounts' element = { <Accounts />}  /> 
              <Route path='/updates' element = { <Updates />}  /> 
              <Route path='/adjustments' element = { <Adjustments />}  /> 
              <Route path='/payments' element = { <Payments />}  /> 
              <Route path='/charges' element = { <Charges />}  /> 
              <Route path='/unpaid' element = { <Unpaid />}  /> 
              <Route path='/pendingprog' element = { <PendingProg />}  /> 
              <Route path='/etemplates' element = { <EmailTemplates />}  /> 
              <Route path='/balancing' element = { <Balancing />}  /> 
              <Route path='/communications' element = { <Communications />}  /> 
              <Route path='/bulkload' element = { <BulkLoad />}  /> 
              <Route path='/outilities' element = { <OtherUtilities />}  />
              <Route path='/signin' element = { <SignIn />}  />
              <Route path='/forgotpassword' element = { <ForgotPassword />}  />
              <Route path='/forgotid' element = { <ForgotId />}  />
              <Route path='/signup' element = { <SignUp />}  />
              </Routes>
       </Router>
      </>
    );
  }
}
export default App;